import React, { useState, useContext, useEffect } from 'react';
import { ContextState } from '../../../../widget/context/ContextMain'
import LoadingPage from '../../../../widget/Loading/LoadingPage';
import { Input, message, Select } from 'antd';
import { toast } from 'react-toastify';
import { baseUrl, customApi } from '../../../../widget/apis';

function ProductEditAsShopWidget(props: any) {
  const { Option } = Select;
  const Ctx = useContext(ContextState);
  const [displayPage, setDisplayPage] = useState<any>([])
  const [termChildList, setTermChildList] = useState<any>([])


  // Function For Upload Handel

  const changeInputChild = (type: string, index: any, value: any) => {
    let data = [...props.childList];
    for (let i in data) {
      if (+i == index) {
        if (type === "discount") {
          if (value <= 100) data[i][type] = value;
        } 
        else if(type === 'is_item_have_discount'){
          data[i][type] = value
        }
        else data[i][type] = value;
        data[i]["isChildStructureChanged"] = true
      }
    }
    props.setChildList(data)
  }

  const changeStockCount = (e: any, index: number, index_iframe: number) => {
    let data = [...props.childList];
    let termData = [...termChildList];
    // if (+e.target.value <= termData[index].stock && +e.target.value >= 0) {
    // data[index].stock = +e.target.value;
    data[index]['frame_sizes'][index_iframe]['stock'] = +e.target.value;
    data[index]["isChildStructureChanged"] = true
    // } else {
    //   message.warning("You can't set stock more than original stock")
    // }
    props.setChildList(data)
  }



  const updateMulti = async (children: any) => {

    if (!children.isChildStructureChanged) return toast("isChildStructureChanged state has problem", { type: "error" })
    if (await checkValidation() == false) {
      return
    }
    var variantsIdList = []
    for (var t in children.frame_sizes) {
      variantsIdList.push({ id: children.frame_sizes[t].id, stock: children.frame_sizes[t].stock })
    }
    children["variants"] = [...variantsIdList]
    const postBody = {
      variants: children.variants,
      price: children.price,
      discount: children.discount ,
      is_item_have_discount:children?.is_item_have_discount
    }
    setDisplayPage(true)
    const req = await customApi({ url: `${baseUrl}${localStorage.getItem("shopId")}/variants/update_multi_as_shop` }, { method: "POST", body: JSON.stringify(postBody), token: true })
    if (req.status) {
      await props.updateData()
      toast(req.message, { type: "success" })
    } else {
      toast(req.message, { type: "error" })
    }
    setDisplayPage(false)
  }

  useEffect(() => {
    if (props.productDetail) {
      setDisplayPage(false)
    }
  }, [props.productDetail])


  const checkValidation = async () => {
    var listUE: any = [...props.childList]
    if (props.childList.length == 0) {
      toast("please select child", { type: "info" })
      return false
    } else {
      for (let i in props.childList) {
        if ((props.childList[i].price - (props.childList[i].price * (props.childList[i].discount / 100))) < props.childList[i].min_price) {
          // toast(` Final price not be less than minimum price in ${i+1}th variety `, { type: "warning" })
          listUE[i].hasError = true
          listUE[i].errorMes = ` Final price should not be less than minimum selling price (minimum is ${props.childList[i].min_price} AED) `
          props.setChildList(listUE)
          return false
        } else {
          setTimeout(() => {
            delete listUE[i].errorMes
          }, 700)
          listUE[i].hasError = false
          props.setChildList(listUE)
        }
        if (props.childList[i].price == 0) {
          toast("Price and stock should not be zero", { type: "info" })
          return false
        }
      }
      return true
    }
  }

  useEffect(() => {
    setTermChildList([...props.childList])
  }, [props.childList.length])

  return (

    <>
      {displayPage ? <LoadingPage /> : ""}

      {/* <h4 className="mt-8  text-glasses-text-50"> Stock , price and discount </h4> */}
      {props.childList.map((item: any, index: any) =>
        <div key={index} className={` w-full flex flex-col border border-dashed  rounded mt-2 mb-6 p-2 relative ${item.hasError ? " border-red-300" : " border-gray-300 "} transition-all duration-700`}>
          <div className={` absolute left-[-15px] top-[-10px] p-2 rounded-lg shadow-lg text-gray-700 ${item.hasError ? " bg-red-100" : " bg-white "} transition-all duration-700 `}>{index + 1}</div>
          <div className={` w-full px-3 py-2 bg-red-100 text-xs shadow-xl rounded-lg ${item.hasError ? " max-h-[250px] opacity-100 visible " : " max-h-0 opacity-0 invisible "}  transition-all duration-700 `}>
            {item.errorMes}
          </div>
          {item.frame_sizes?.map((item_iframe: any, index_iframe: number) =>
            <div key={index_iframe} className="flex w-full mt-2 ">
              <div className="w-6/12 pr-1 ">
                <div className="flex flex-col items-center w-full ">
                  <span className=" text-glasses-text-50">Frame Size</span>
                  <div className=" w-full h-[40px] ">
                    <input type="number" value={item_iframe.frame} className="w-full h-full text-center bg-white rounded shadow cursor-not-allowed  text-glasses-text-50 focus:outline-none" disabled />
                  </div>
                </div>
              </div>
              <div className="w-6/12 pl-1 ">
                <div className="flex flex-col items-center w-full ">
                  <span className=" text-glasses-text-50">Stock</span>
                  <div className=" w-full h-[40px] ">
                    <input type="number" onChange={(e: any) => changeStockCount(e, index, index_iframe)} value={item_iframe.stock} className="w-full h-full text-center bg-white rounded shadow  text-glasses-text-50 focus:outline-none" />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="flex w-full mt-2 ">

            <div className="w-4/12 px-1 ">
              <div className="flex flex-col items-center w-full ">
                <span className=" text-glasses-text-50">Frame Color</span>
                <div className=" w-full h-[40px]  bg-white shadow rounded flex items-center justify-center text-gray-600 text-sm">
                  <img src={item.color} alt="color" className=' w-[25px] h-[25px] rounded-full' />
                </div>
              </div>
            </div>

            <div className="w-4/12 px-1 ">
              <div className="flex flex-col items-center w-full ">
                <span className=" text-glasses-text-50">Price</span>
                <div className=" w-full h-[40px] ">
                  <input value={item.price} onChange={(e) => { changeInputChild('price', index, e.target.value) }} type=" number" placeholder={'Price'} className="w-full h-full text-center bg-white rounded shadow  text-glasses-text-50 focus:outline-none" />
                </div>
              </div>
            </div>

            <div className="w-4/12 px-1 ">
              <div className="flex flex-col items-center w-full ">
                <span className=" text-glasses-text-50">Discount %</span>
                <div className=" w-full h-[40px] ">
                  <input value={item.discount} onChange={(e) => { changeInputChild('discount', index, +e.target.value) }} type="number" max={100} placeholder={'Discount'} className="w-full h-full text-center bg-white rounded shadow  text-glasses-text-50 focus:outline-none" />
                </div>
              </div>
            </div>

          </div>

          <div className="flex w-full mt-2 ">

            <div className="w-6/12 px-1 ">
              <div className="flex flex-col items-center w-full ">
                <span className=" text-glasses-text-50">Lens Color</span>
                <div className=" w-full h-[40px]  bg-white shadow rounded flex items-center justify-center text-gray-600 text-sm">
                  {item.lens_color}
                </div>
              </div>
            </div>
            <div className="w-6/12 px-1 ">
              <div className="flex flex-col items-center w-full ">
                <span className=" text-glasses-text-50">Lens Type</span>
                <div className=" w-full h-[40px]  bg-white shadow rounded flex text-center items-center justify-center text-gray-600 text-xs">
                  {item.lens_type ? item.lens_type : "not set"}
                </div>
              </div>
            </div>
          </div>
          <label className='  mt-4 flex items-center '>
            <input defaultValue={item?.is_item_have_discount} onChange={(e) => { changeInputChild('is_item_have_discount', index, e.target.checked) }} defaultChecked={item.is_item_have_discount == 1 ? true : false} type='checkbox' />
            <span className='ml-2'>View on discount page</span>
          </label>

          {item.description ?
            <p className='w-full my-2 mt-4 '>
              {item.description}
            </p>
            :
            ""
          }

          <>
            <div onClick={() => updateMulti(item)}
              className={` ${!item.isChildStructureChanged ? " bg-gray-300 cursor-not-allowed max-h-0 opacity-0 invisible " : " max-h-[150px] opacity-100 visible bg-glasses-head-50 cursor-pointer "} transition-all duration-500
             text-white w-full flex justify-center items-center rounded-lg h-[30px] mt-2 font-bold mx-auto `}>
              Update information
            </div>
          </>

        </div>
      )}


    </>
  );
}

export default ProductEditAsShopWidget;
